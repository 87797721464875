import moment from 'moment'

export const filterDataDestinationByValue = (options = [], destination = '') => {
  const match = []
  options.forEach((item) => {
    const elements = destination.split('-')
    let sum = 0
    if (item?.value) {
      elements.forEach((i) => {
        sum += item?.value.indexOf(i)
      })
      if (sum >= 0) match.push(item)
    }
  })
  return match[0]?.value ?? ''
}

// TODO: remove this function after add a new function after this
export const between30Days = (data = []) => {
  const afterTo = moment().subtract(30, 'days')
  const dataBetweenDays = []
  data.forEach((element) => {
    const validDate = moment(element.x)

    const insertIntoNewArray = validDate.isAfter(afterTo, 'day')
    if (insertIntoNewArray) {
      dataBetweenDays.push(element)
    }
  })
  return dataBetweenDays
}

export const filterTypeRules = (arrayRules, filterId) => {
  if (Array.isArray(arrayRules)) {
    if (typeof filterId === 'string') {
      const rules = arrayRules.filter((rule) => rule?._id === filterId)
      if (rules.length > 0) {
        return rules[0]?.name ?? ''
      }
    }
  }

  return ''
}

export const chooseBetweenZipCodes = (dataZip = []) => {
  const selectZipCode = (value) => {
    const { data } = value
    const { items } = data
    if (items.length > 1) {
      const regex = /^[0-9]*$/
      for (const x of items) {
        const postal = regex.test(x.address.postalCode)
        if (postal) return x.address.postalCode
      }
    } return items[0].address.postalCode
  }
  return dataZip.map((x) => selectZipCode(x))
}

export const removeRulesNoFlatAndMarginZero = (rules = []) => {
  const clearRules = [...rules]
  if (rules.length > 0) {
    return clearRules.filter((rule) => !(rule.flat === 0 && rule.margin === 0))
  }
  return clearRules
}

export const isNone = (customer = {}) => {
  let result = false
  const { considerCustomFuelPrice, customPrice, defaultFuel } = customer
  if (considerCustomFuelPrice && customPrice && defaultFuel) {
    result = false
  }
  if (!considerCustomFuelPrice && customPrice && defaultFuel) {
    result = false
  }
  if (!considerCustomFuelPrice && !customPrice && defaultFuel) {
    result = false
  }
  if (!considerCustomFuelPrice && !customPrice && !defaultFuel) {
    result = true
  }


  if (considerCustomFuelPrice && !customPrice && !defaultFuel) {
    result = false
  }
  if (considerCustomFuelPrice && customPrice && !defaultFuel) {
    result = false
  }
  if (considerCustomFuelPrice && !customPrice && defaultFuel) {
    result = false
  }


  if (!considerCustomFuelPrice && customPrice && !defaultFuel) {
    result = true
  } 



  return result
}
