import { put, takeLatest, call } from 'redux-saga/effects'
import { users } from 'services'

import { requestUserEditAdmin, successUserEditAdmin, failureUserEditAdmin } from 'store/duck/userEditAdmin.duck'

function* UserEdiAdmintAsync(data) {
  try {
    const response = yield call(users.getUser, data.payload.data)
    yield put(successUserEditAdmin(response, 'success'))
  } catch (error) {
    yield put(failureUserEditAdmin(error.response.data.message))
  }
}

function* watctUserEditAdminAsync() {
  yield takeLatest(requestUserEditAdmin, UserEdiAdmintAsync)
}

export default watctUserEditAdminAsync
