import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Swal from 'sweetalert2'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import { batch, useDispatch, useSelector } from 'react-redux'
import { requestGetMultiSteps, clearGetMultiSteps } from 'store/duck/getMultiSteps.duck'
import { requestGetRules } from 'store/duck/getRules.duck'
import { clearDeleteMultiStep, requestDeleteMultiStep } from 'store/duck/deleteMultiStep.duck'
import { clearUpdateMultiStep } from 'store/duck/updateMultiStep.duck'
import { requestUpdateStatusMultiStep, clearUpdateStatusMultiStep } from 'store/duck/updateStatusMultiStep.duck'
import { requestGetRuleSets } from 'store/duck/getRuleSets.duck'
import ModalDeleteRules from 'components/ModalDeleteRules'
import notify from 'utils/notify'
import Table from 'components/Table'
import FilterMultiStep from 'components/Form/FilterMultiStep'
import { replaceMultiStepActions } from 'utils/formatValues'
import MultiStepColumns from './utils/MultiStepColumns'
import RulesContainer from '../styles'

import { ShowItems } from './utils/ShowItems'

const ListMultiStep = ({ enableEdit = true }) => {
  const dispatch = useDispatch()
  const [params, setParams] = useState({ text: '', page: 1, status: '', limit:50 })
  const { multiSteps, success, error, fetching, totalRecords } = useSelector((state) => state.getMultiSteps)
  const { multiSteps: multiStepsByCustomer, success: successMultiStepByCustomer, totalRecords: totalRecordsByCustomer, fetching: fetchingByCustomer } = useSelector((state) => state.getMultiStepsByCustomer)
  const {
    successDelete,
    message: messageDeleteMulti,
    fetching: fetchingDelete,
  } = useSelector((state) => state.deleteMultiStep)
  const { success: successCreate } = useSelector((state) => state.createMultiStep)
  const {
    message,
    successUpdateStatus,
    error: errorUpdateStatusMultiStep,
  } = useSelector((state) => state.updateStatusMultiStep)
  const { successUpdate } = useSelector((state) => state.updateMultiStep)
  const { success: returnVersion } = useSelector((state) => state.returnVersionMultistep)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [rulesData, setRulesData] = useState(multiSteps)
  const [openDelete, setOpenDelete] = useState(false)
  const totalRules = enableEdit ? totalRecords : totalRecordsByCustomer
  const loading = enableEdit ? fetching : fetchingByCustomer


  useEffect(() => {
    if (enableEdit) dispatch(requestGetMultiSteps(params))
  }, [params, dispatch, enableEdit])

  useEffect(() => {
    setRulesData([])
    if (success && enableEdit) {
      setRulesData(multiSteps)
    }
    if (!enableEdit && successMultiStepByCustomer) {
      setRulesData(multiStepsByCustomer)
    }
  }, [dispatch, success, multiSteps, enableEdit, successMultiStepByCustomer, multiStepsByCustomer])

  useEffect(() => {
    if (successCreate || successUpdate || successUpdateStatus || returnVersion) {
      dispatch(requestGetMultiSteps(params))
    }
    if (successUpdate) {
      dispatch(clearUpdateMultiStep())
    }
    if (successUpdateStatus) {
      dispatch(clearUpdateStatusMultiStep())
    }
    if (successDelete) {
      setOpenDelete(false)
      notify('success', 'Rules has been deleted')
      dispatch(clearDeleteMultiStep())
      dispatch(requestGetMultiSteps(params))
      dispatch(requestGetRuleSets())
    }
    if (errorUpdateStatusMultiStep) {
      notify('error', message)
    }
  }, [
    params,
    dispatch,
    successCreate,
    successUpdate,
    successDelete,
    successUpdateStatus,
    errorUpdateStatusMultiStep,
    message,
    returnVersion,
  ])

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error getting multi-step rules!',
      })
      dispatch(clearGetMultiSteps())
    }
    if (messageDeleteMulti) {
      notify('error', messageDeleteMulti)
      dispatch(clearDeleteMultiStep())
    }
  }, [dispatch, error, messageDeleteMulti])

  useEffect(() => {
    dispatch(requestGetRules())
  }, [dispatch])

  const [expandedRowKeys, setExpandedKey] = useState('')

  const onExpand = (key) => {
    setExpandedKey((prev) => {
      const newKey = key._id
      return prev !== newKey ? newKey : null
    })
  }

  const columns = useMemo(
    () =>
      replaceMultiStepActions(
        MultiStepColumns(dispatch, setIsOpenCreateModal, onExpand, expandedRowKeys),
        enableEdit,
        dispatch,
        setIsOpenCreateModal
      ),
    [dispatch, expandedRowKeys, enableEdit]
  )

  const handleTableChange = useCallback((pagination) => {
    setParams((prevParams) => ({
      ...prevParams,
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const showModalDelete = () => {
    setOpenDelete(true)
  }
  const closeModalDelete = () => {
    setOpenDelete(false)
  }
  const [rulesToDelete, setRulesToDelete] = useState({ rules: [], multiStep: [] })
  const handleData = useCallback(
    (rows) => {
      setRulesToDelete({
        rules: rows
          .flatMap((id) => [...rulesData.filter((rule) => rule._id === id)])
          .flatMap((sen) => [...sen?.rulesetsInfo?.ruleSetsNames]),
        multiStep: rows,
      })
    },
    [rulesData]
  )

  const textBody = () => (
    <div style={{ fontWeight: 400, fontSize: '18px', textAlign: 'center' }}>
      Are you sure you want to delete this <span style={{ fontWeight: 700 }}>Multi-Step Rule</span>? If you accept,
      the rule will be removed from the following RuleSets as well:
    </div>
  )

  const multipleDelete = () => {
    batch(() => {
      rulesToDelete.multiStep.map((id) => dispatch(requestDeleteMultiStep(id)))
    })
  }

  return (
    <RulesContainer>
      <FilterMultiStep
        isOpenCreateModal={isOpenCreateModal}
        setIsOpenCreateModal={setIsOpenCreateModal}
        enableEdit={enableEdit}
      />
      <Table
        enableEdit={enableEdit}
        columns={columns}
        data={rulesData}
        loading={loading}
        rowKey="_id"
        width="100%"
        padding="4px 0px"
        margin="0px"
        pagination={{ total: totalRules }}
        updatedPagination
        onChange={handleTableChange}
        limitPerPage={50}
        rowSelection
        dispatch={dispatch}
        onExpand={onExpand}
        expandedRowKeys={expandedRowKeys}
        onRow={(_) => {
          return [expandedRowKeys].includes(_._id) && { className: 'expand-parent' }
        }}
        expandedRowRender={(record) => {
          return (
            <Row>
              <Col
                span={10}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Row style={{ width: '100%' }}>
                  <ShowItems sentences={record?.sentences} />
                </Row>
              </Col>
              <Col span={14}>{null}</Col>
            </Row>
          )
        }}
        status={params.status}
        pauseRequest={requestUpdateStatusMultiStep}
        openDeleteModal={showModalDelete}
        handleData={handleData}
        CustomModalDelete={
          <ModalDeleteRules
            visible={openDelete}
            ruleSets={rulesToDelete.rules ?? []}
            close={closeModalDelete}
            componentBody={textBody}
            onClick={() => multipleDelete()}
            fetching={fetchingDelete}
          />
        }
        addHeight={-30}
        entityNameForBulk="Multi-Step"
      />
    </RulesContainer>
  )
}

export default ListMultiStep
