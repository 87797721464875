import React from 'react' 
import Col from 'antd/lib/col'

export const CreateName = ({ item = {} }) => { 
  const negation = item.negation ? <span style={{ color: '#3594FF' }}>Not</span> : ''
  const name = item.ruleId?.name ?? item.ruleName
  const operator = <span style={{ color: '#3594FF' }}>{item.condition}</span>
  return (
    <div>
      If {negation} {name} {operator}
    </div>
  )
}

export const ShowItems = ({ sentences, span }) => {
  return sentences?.map((item) => (
    <Col
      key={`${item.ruleId?.name ?? item.ruleName}-`}
      offset={15}
      span={span ?? 11}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0px 15px',
      }}
    > 
      <CreateName item={item} />
    </Col>
  ))
}
