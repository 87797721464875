import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux' 
import { Typography, Row, Col } from 'antd'
import { uniqueId } from 'lodash'
import paginate from 'utils/paginate'
import Modal from 'components/Modal'
import Table from 'components/Table'
import { ShowItems } from 'containers/rules/ListMultiStep/utils/ShowItems'
import { getConditionalsColumn } from 'containers/rules/ListMultiStep/utils/MultiStepColumns'
import { removeRulesNoFlatAndMarginZero } from 'utils/filters'

import RuleAdjustmentColumns from './utils/TableColumns'

const { Title } = Typography

const RulesModal = ({
  ruleAdjustment = [],
  multiStepAdjustment = [],
  viewRate = false,
  visible = false,
  onClose = () => '',
}) => { 
  const returnRules = (state) => state?.getRate?.rate?.ruleAdjustment ?? []
  const returnMultiStep = (state) => state?.getRate?.rate?.multiStepAdjustment ?? []
  const { ruleAdjustments, multiSteptAdjustments: multiStepsWithoutIds } = useSelector((state) => ({
    ruleAdjustments: viewRate ? ruleAdjustment : returnRules(state),
    multiSteptAdjustments: viewRate ? multiStepAdjustment : returnMultiStep(state),
  }))
  const clearRulesAdjustments = removeRulesNoFlatAndMarginZero(ruleAdjustments)
  const clearMultiSteps = removeRulesNoFlatAndMarginZero(multiStepsWithoutIds)

  const [expandedRowKeys, setExpandedKey] = useState('')

  const multiStepAdjustments = useMemo(
    () => clearMultiSteps.map((props) => ({ ...props, _id: uniqueId() })),
    [clearMultiSteps]
  )

  const [rulePagination, setRulePagination] = useState({ page: 1, limit: 3 })
  const [multistepPagination, setMultistepPagination] = useState({ page: 1, limit: 3 })

  const onExpand = (key) => {
    setExpandedKey((prev) => {
      const newKey = key._id
      return prev !== newKey ? newKey : null
    })
  }

  const MultiSteptColumns = [
    {
      title: 'Multi Step Rule Name',
      dataIndex: 'multiStepName',
      key: 'multiStepName',
      align: 'left',
      render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
      width: 200,
    },
    {
      title: 'Rule Conditions',
      dataIndex: 'sentences',
      key: 'sentences',
      align: 'left',
      render: (sentences, rate) => getConditionalsColumn(sentences, rate, onExpand, expandedRowKeys),
      width: 300,
    },
    {
      title: 'Percentage  %',
      dataIndex: 'margin',
      key: 'margin',
      align: 'right',
      render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
      width: 125,
    },
    {
      title: 'Flat',
      dataIndex: 'flat',
      key: 'flat',
      align: 'right',
      render: (text) => <div style={{ fontSize: 16 }}>{text}</div>,
      width: 125,
    },
  ]

  const handleRuleChange = useCallback((pagination) => {
    setRulePagination((prevParams) => ({
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  const handleMultistepChange = useCallback((pagination) => {
    setMultistepPagination((prevParams) => ({
      page: pagination.current ?? prevParams.page,
      limit: pagination.limit ?? prevParams.limit,
    }))
  }, [])

  return (
    <>
      {/* <ButtonText onClick={() => setVisible((state) => !state)}>
        <BsEyeFill />
        <span style={{ margin: '0 3px', fontSize: '16px' }}> View All Rules</span>
      </ButtonText> */}
      <Modal
        title={
          <h1
            style={{
              marginBottom: '5px',
              fontWeight: '700',
              fontSize: '16px',
              color: '#002555',
            }}
          >
            All Rules
          </h1>
        }
        visible={visible}
        padding="0 24px 24px"
        onCancel={onClose}
      >
        <Title style={{ fontWeight: '700', fontSize: '16px', color: '#002555', marginTop: 20, marginBottom: 10 }}>
          Rules Adjustment Details
        </Title>
        <Table
          columns={RuleAdjustmentColumns}
          data={paginate(clearRulesAdjustments, rulePagination.limit, rulePagination.page)}
          rowKey="id"
          width="100%"
          height="230px"
          padding="4px 2px"
          pagination={{ total: clearRulesAdjustments.length }}
          updatedPagination
          limitPerPage={5}
          showBulkActions={false}
          onChange={handleRuleChange}
          addHeight={-400}
        />

        <Title
          level={4}
          style={{ color: '#002555', fontWeight: '700', fontSize: '16px', marginTop: 20, marginBottom: 10 }}
        >
          Multi Step Adjustments
        </Title>
        <Table
          columns={MultiSteptColumns}
          data={paginate(multiStepAdjustments, multistepPagination.limit, multistepPagination.page)}
          rowKey="_id"
          width="100%"
          height="230px"
          padding="4px 2px"
          pagination={{ total: multiStepAdjustments.length }}
          updatedPagination
          limitPerPage={5}
          showBulkActions={false}
          expandedRowKeys={expandedRowKeys}
          onChange={handleMultistepChange}
          onRow={(_) => {
            return [expandedRowKeys].includes(_._id) && { className: 'expand-parent' }
          }}
          expandedRowRender={(record) => {
            return (
              <Row>
                <Col
                  span={10}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Row style={{ width: '100%' }}>
                    <ShowItems sentences={record?.sentences} span={20} />
                  </Row>
                </Col>
                <Col span={14}>{null}</Col>
              </Row>
            )
          }}
          addHeight={-400}
        />
      </Modal>
    </>
  )
}

export default RulesModal
