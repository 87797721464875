import React, { useEffect, useState } from 'react'
import Button from 'antd/lib/button'
import { MdClose } from 'react-icons/md'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import colors from 'providers/theme/config/colorPallete'
import { useDispatch, useSelector } from 'react-redux'
import { addRule } from 'store/duck/createRule.duck'
import { requestGetRuleHistory, clearGetRuleHistory } from 'store/duck/getRuleHistory.duck'
import { requestDeleteRule } from 'store/duck/deleteRule.duck'
import { clearReturn, requestReturnVersionRule } from 'store/duck/returnVersionRule.duck'
import { clearValidateState } from 'store/duck/validateState.duck'
import StyledTabs from 'components/Tabs'
import TitleDrawer from 'components/TitleDrawer'
import HistoryItems from 'components/HistoryItem'
import PrimarySubmit from 'components/PrimarySubmit'
import useAutocompleteZip from 'hooks/useAutocompleteZip'
import { RULE_GEOGRAPHIC, RULE_SIMPLE, RULE_DATE_RANGE, RULE_LENGTH, EQUIPMENT_TYPE, RULE_LANE_SCORE, RULE_MCI, RULE_LTRATIO } from 'utils/constants'
import notify from 'utils/notify'
import CreateRuleForm from './RuleForm'
import { StyledModal } from './styles'

const defaultActiveKeyEditTab = '1'
const CreateRuleModal = ({
  typesId,
  shippingRule,
  referenceRule,
  columnsParams,
  visible,
  onCloseModal,
  addNewRuleForCreateMultistep,
  masterRule = false,
  enableEdit = true,
}) => {
  const dispatch = useDispatch()
  const { success, data, editRule, ruleSelected, error, message } = useSelector((state) => state.createRule)
  const { getHistory, successHistory } = useSelector((state) => ({
    getHistory: state.getRuleHistory.history,
    successHistory: state.getRuleHistory.success,
  }))
  const {
    success: returnSuccess,
    message: returnMessage,
    error: returnError,
  } = useSelector((state) => state.returnVersionRule)
  const { success: successRules, rules } = useSelector((state) => state.getRules)
  const { success: successCreateMultistep } = useSelector((state) => state.createMultiStep)
  const [loading, setLoading] = useState()
  const [createdRuleId, setCreatedRuleId] = useState('')
  const [deleteRuleWhenClosingModal, setDeleteRuleWhenClosingModal] = useState(false)
  const [clickedOpenCreateMultiStep, setClickedOpenCreateMultiStep] = useState(false)
  const [clickedAddToRulesets, setClickedAddToRulesets] = useState(false)
  const [activeKeyEditTab, setActiveKeyEditTab] = useState(defaultActiveKeyEditTab)
  const autocompleteZip = useAutocompleteZip()
  const { data: dataValidate } = useSelector((state) => state.validateState)
  const hasState = JSON.stringify(dataValidate) !== '{}'
  useEffect(() => {
    if (visible && ruleSelected?._id) {
      dispatch(requestGetRuleHistory({ id: ruleSelected._id }))
    }
  }, [dispatch, visible, ruleSelected])
  useEffect(() => {
    if (returnSuccess && !successRules) {
      notify('success', returnMessage)
      dispatch(clearReturn())
      onCloseModal(false)
    }
    const labelError = error ? message : ''
    const displayMessage = returnError ? returnMessage : labelError

    if (returnError || error) {
      notify('error', displayMessage)
      dispatch(clearReturn())
    }
    if (successHistory) {
      dispatch(clearGetRuleHistory())
    }
  }, [
    dispatch,
    returnSuccess,
    ruleSelected,
    returnMessage,
    returnError,
    successRules,
    rules,
    successHistory,
    onCloseModal,
    error,
    message,
  ])
  useEffect(() => {
    if (success) {
      setDeleteRuleWhenClosingModal(true)
      setCreatedRuleId(data.id)
    }
  }, [success, data.id])
  useEffect(() => {
    if (successCreateMultistep) {
      setDeleteRuleWhenClosingModal(false)
    }
  }, [successCreateMultistep])
  const variant = (() => {
    if (addNewRuleForCreateMultistep) {
      return 'addForMultiStep'
    }
    if (editRule) {
      return 'edit'
    }
    return 'create'
  })()
  const title = {
    addForMultiStep: 'Add New Rule',
    edit: 'Edit Rule',
    create: 'Create New Rule',
  }[variant]
  const dateRangeRule = 653
  const geographicRule = 540
  const truckType = 480
  const lengthRule = 500
  const simpleRule = 550

  const heightByRuleType = {
    [RULE_DATE_RANGE]: dateRangeRule,
    [RULE_GEOGRAPHIC]: geographicRule,
    [RULE_LENGTH]: lengthRule,
    [RULE_SIMPLE]: simpleRule,
    [EQUIPMENT_TYPE]: truckType,
    geographicRule,
    dateRangeRule,
    truckType,
    lengthRule,
    simpleRule,
    [RULE_LANE_SCORE]:653,
    [RULE_MCI]:653,
    [RULE_LTRATIO]:653 
  }
  const queryParams = ruleSelected?.ruleModel ? ruleSelected?.ruleModel : columnsParams.type
  const formName = variant === 'addForMultiStep' ? 'add-rule-form' : 'create-rule-form'
  const heightRule = `${queryParams ? heightByRuleType[queryParams] : 500}px`
  const isOpenAnotherForm = clickedOpenCreateMultiStep ? true : clickedAddToRulesets

  return (
    <StyledModal
      title={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          {variant === 'addForMultiStep' && (
            <AiOutlineArrowLeft
              style={{ color: '#000000', fontSize: '20px', marginRight: 6, cursor: 'pointer' }}
              onClick={() => onCloseModal(false)}
            />
          )}
          <TitleDrawer title={title} />
        </div>
      }
      width={500}
      height={heightRule}
      style={{ top: 100 }}
      afterClose={() => {
        if (editRule) {
          setActiveKeyEditTab(defaultActiveKeyEditTab)
          dispatch(addRule())
        }
        if (hasState) dispatch(clearValidateState())
      }}
      onCancel={() => {
        onCloseModal(false)
        if (deleteRuleWhenClosingModal && isOpenAnotherForm) {
          dispatch(requestDeleteRule(createdRuleId))
          setDeleteRuleWhenClosingModal(false)
          setCreatedRuleId('')
        }
      }}
      visible={visible}
      bodyStyle={{ padding: editRule ? '0px 16px' : '16px 24px 0px' }}
      destroyOnClose
      footer={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            flexGrow: 1,
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <PrimarySubmit
            disable={!enableEdit}
            label={editRule ? 'Edit Rule' : 'Save Rule'}
            formName={formName}
            loading={loading}
          />
          {variant !== 'addForMultiStep' && (
            <>
              <Button
                disabled={!enableEdit}
                type="text"
                style={{
                  backgroundColor: 'white',
                  marginLeft: 0,
                  marginTop: 16,
                  border: `1px solid ${colors.light_blue.default}`,
                  borderRadius: 4,
                  height: 40,
                }}
                onClick={() => setClickedOpenCreateMultiStep(true)}
              >
                <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Create Multi-Step rule</span>
              </Button>
              <Button
                disabled={!enableEdit}
                type="text"
                style={{ marginLeft: 0, marginTop: 16, height: 40 }}
                onClick={() => setClickedAddToRulesets(true)}
              >
                <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Add to RuleSets</span>
              </Button>
            </>
          )}
          {variant === 'addForMultiStep' && (
            <Button
              disabled={!enableEdit}
              type="text"
              style={{ backgroundColor: 'white', marginLeft: 0, marginTop: 16 }}
              onClick={() => onCloseModal(false)}
            >
              <span style={{ color: colors.light_blue.default, fontWeight: 400 }}>Cancel Creation</span>
            </Button>
          )}
        </div>
      }
      closeIcon={<MdClose size={24} color="#000000" />}
    >
      {editRule ? (
        <StyledTabs
          activeKey={activeKeyEditTab}
          onChange={(newActiveKey) => setActiveKeyEditTab(newActiveKey)}
          tabBarStyle={{ width: '100%', marginBottom: 10 }}
        >
          <StyledTabs.TabPane tab="Rules Info" key="1">
            <CreateRuleForm
              masterRule={masterRule}
              variant={variant}
              onCloseModal={onCloseModal}
              typesId={typesId}
              shippingRule={shippingRule}
              referenceRule={referenceRule}
              columnsParams={columnsParams}
              addNewRuleForCreateMultistep={addNewRuleForCreateMultistep}
              autocompleteZip={autocompleteZip}
              formName={formName}
              onChangeLoading={(newLoading) => setLoading(newLoading)}
              clickedOpenCreateMultiStep={clickedOpenCreateMultiStep}
              clickedAddToRulesets={clickedAddToRulesets}
              setClickedOpenCreateMultiStep={setClickedOpenCreateMultiStep}
              setClickedAddToRulesets={setClickedAddToRulesets}
              enableEdit={enableEdit}
            />
          </StyledTabs.TabPane>
          <StyledTabs.TabPane tab="History" key="2">
            <HistoryItems
              enableEdit={enableEdit}
              rules
              id="ruleId"
              request={requestReturnVersionRule}
              data={getHistory}
              category={ruleSelected?.ruleModel ?? 'geographicRule'}
              height={`${heightRule - 50}px`}
            />
          </StyledTabs.TabPane>
        </StyledTabs>
      ) : (
        <CreateRuleForm
          masterRule={masterRule}
          variant={variant}
          onCloseModal={onCloseModal}
          typesId={typesId}
          shippingRule={shippingRule}
          referenceRule={referenceRule}
          columnsParams={columnsParams}
          addNewRuleForCreateMultistep={addNewRuleForCreateMultistep}
          autocompleteZip={autocompleteZip}
          formName={formName}
          onChangeLoading={(newLoading) => setLoading(newLoading)}
          clickedOpenCreateMultiStep={clickedOpenCreateMultiStep}
          clickedAddToRulesets={clickedAddToRulesets}
          setClickedOpenCreateMultiStep={setClickedOpenCreateMultiStep}
          setClickedAddToRulesets={setClickedAddToRulesets}
        />
      )}
    </StyledModal>
  )
}
export default CreateRuleModal
