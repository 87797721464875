import Menu from 'antd/lib/menu'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MoreDropdown from 'components/MoreDropdown'
import ModalConfirmation from 'components/ModalConfirmation'
import { loadMultiStep } from 'store/duck/createMultiStep.duck'
import { requestDeleteMultiStep } from 'store/duck/deleteMultiStep.duck'
import { getStatus, EnumStatus } from 'utils/getStatus'
import { AiOutlineCheck } from 'react-icons/ai'
import { requestUpdateStatusMultiStep } from 'store/duck/updateStatusMultiStep.duck'
import { MdBlock, MdDelete, MdModeEdit, MdPauseCircleFilled } from 'react-icons/md'
import ModalDeleteRules from 'components/ModalDeleteRules'
import { StyledCol, StyledRow } from './styles'

const sizeIcon = '20'
const paddingItem = { padding: '0px 8px 0px 8px' }

const ActionsDropdown = ({ record, openDrawer, status }) => {
  const dispatch = useDispatch()
  const { fetching, successDelete } = useSelector((state) => state.deleteMultiStep)
  const [propsModal, setPropsModal] = useState({
    visible: false,
    message: 'Are you sure you want to delete this Rule?',
    // eslint-disable-next-line react/jsx-props-no-spreading
    icon: (props) => <MdDelete {...props} />,
    textButon: 'Delete',
    textSuccessfull: 'Rule has been deleted',
    actionConfirm: (some) => some,
  })

  const loadRuleSelected = (data) => {
    dispatch(loadMultiStep(data))
    openDrawer(true)
  }

  const [visibleDelete, setVisibleDelete] = useState(false)

  useEffect(() => {
    if (successDelete) {
      setVisibleDelete(false)
    }
  }, [successDelete])

  const openDeleteModal = () => {
    setVisibleDelete(true)
  }
  const closeDeleteModal = () => {
    return setVisibleDelete(false)
  }

  const messageModal = {
    [EnumStatus.Activated]: 'activated',
    [EnumStatus.Paused]: 'paused',
    [EnumStatus.Deactivated]: 'blocked',
  }

  const handleUpdateStatus = async (id = '', newStatus = '') => {
    const dataToStatus = {
      id,
      status: newStatus,
    }
    setPropsModal({
      visible: true,
      message: `Are you sure you want to ${messageModal[newStatus]} this Rule?`,
      // eslint-disable-next-line react/jsx-props-no-spreading
      icon: (props) => <AiOutlineCheck {...props} />,
      textButon: messageModal[newStatus].charAt(0).toUpperCase() + messageModal[newStatus].slice(1),
      textSuccessfull: `Rule has been ${newStatus.toLowerCase()}`,
      actionConfirm: () => {
        dispatch(requestUpdateStatusMultiStep(dataToStatus))

        setPropsModal({
          visible: false,
          message: ' ',
          icon: (props) => props,
          textButon: '',
          textSuccessfull: '',
          actionConfirm: (props) => props,
        })
      },
    })
  }

  const assets = {
    [EnumStatus.Activated]: {
      icon: <AiOutlineCheck size={sizeIcon} />,
      text: <span>Activate Multi-Step Rule</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Activated),
    },
    [EnumStatus.Paused]: {
      icon: <MdPauseCircleFilled size={sizeIcon} />,
      text: <span>Pause Multi-Step Rule</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Paused),
    },
    [EnumStatus.Deactivated]: {
      icon: <MdBlock size={sizeIcon} />,
      text: <span>Block Multi-Step Rule</span>,
      onClick: (id) => handleUpdateStatus(id, EnumStatus.Deactivated),
    },
  }

  const displayedStatusModifiers = (id) => {
    return Object.keys(EnumStatus)
      .filter((item) => item !== status)
      .map((row, index) => (
        <Menu.Item key={index} style={paddingItem} onClick={() => assets[row].onClick(id)}>
          <StyledRow>
            <StyledCol>{assets[row].icon}</StyledCol>
            <StyledCol>
              <span>{assets[row].text}</span>
            </StyledCol>
          </StyledRow>
        </Menu.Item>
      ))
  }

  const textBody = () => (
    <div
      style={{
        fontWeight: 400,
        fontSize: '18px',
        textAlign: 'center',
        marginBottom: record.rulesetsInfo?.ruleSetsNames.length === 0 ? 10 : undefined,
      }}
    >
      Are you sure you want to delete this <span style={{ fontWeight: 700 }}>Multi-Step Rule</span>?
      {record.rulesetsInfo?.ruleSetsNames.length > 0 &&
        'If you accept, the rule will be removed from the following RuleSets as well:'}
    </div>
  )

  return (
    <MoreDropdown
      menu={
        <Menu>
          <Menu.Item style={paddingItem} onClick={() => loadRuleSelected(record)}>
            <StyledRow>
              <StyledCol>
                <MdModeEdit size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Edit Multi-Step Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          {displayedStatusModifiers(record._id)}
          <Menu.Item style={paddingItem} onClick={() => openDeleteModal()}>
            <StyledRow>
              <StyledCol>
                <MdDelete size={sizeIcon} />
              </StyledCol>
              <StyledCol>
                <span>Delete Multi-Step Rule</span>
              </StyledCol>
            </StyledRow>
          </Menu.Item>
          <ModalDeleteRules
            visible={visibleDelete}
            ruleSets={[...record.rulesetsInfo?.ruleSetsNames]}
            close={closeDeleteModal}
            componentBody={textBody}
            onClick={() => dispatch(requestDeleteMultiStep(record._id))}
            fetching={fetching}
          />
          <ModalConfirmation
            actionConfirm={propsModal.actionConfirm}
            textSuccessfull={propsModal.textSuccessfull}
            textButton={propsModal.textButon}
            close={() => {
              setPropsModal({
                visible: false,
                message: ' ',
                icon: (props) => props,
                textButon: '',
                textSuccessfull: '',
                actionConfirm: (props) => props,
              })
            }}
            icon={propsModal.icon}
            message={propsModal.message}
            visible={propsModal.visible}
            height="296px"
            width={350}
            centered
          />
        </Menu>
      }
    />
  )
}

const actionsDropdown = (_, openDrawer) => ({
  title: <div style={{ fontSize: '16px' }}> Actions</div>,
  dataIndex: 'actions',
  key: 'actions',
  render: (edit, record) => {
    return (
      <div style={{ display: 'block' }}>
        <ActionsDropdown
          record={record}
          openDrawer={openDrawer}
          status={getStatus(record.status, record.active)}
        />
      </div>
    )
  },
  width: 80,
  align: 'center',
})

export default actionsDropdown
