import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdDashboard, MdAssignment } from 'react-icons/md'
import { IoMdSettings, IoIosList } from 'react-icons/io'
import { ListAlt, AllySmallWhite, AllyLarge } from 'assets/img'
import { Customers } from 'assets/svg'
import { useHistory, useLocation } from 'react-router-dom'
import { removeStorage } from 'utils/storage'
import { logout } from 'store/duck/login.duck'
import TopBar from 'components/TopBar'
import socket from 'utils/socketManager'
import Grid from 'antd/lib/grid'
// import notify from 'utils/notify' 
import { hasTheTokenExpired } from 'utils/handleDates'

import { requestPostRefreshToken } from 'store/duck/refreshToken.duck'
import {
  StyledContainer,
  MenuContainer,
  StyledCollapser,
  StyledMenu,
  StyledItem,
  SidebarHeader,
  LayoutContainer,
  ChildrenContainer,
} from './styles'
import notify from 'utils/notify'


const { useBreakpoint } = Grid

const Layout = ({ children, role }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const screen = useBreakpoint()
  const TOKEN = window.localStorage.getItem('altptk')

  const [expanded, setExpanded] = useState(false)

  let menuKey = location.pathname.slice(1)

  if (menuKey === 'login') {
    menuKey = 'dashboard'
  }

  if (menuKey.includes('/')) {
    const [currentMenuKey] = menuKey.split('/')
    menuKey = currentMenuKey
  }

  const handleClickMenu = ({ key }) => {
    if (key === 'rules') {
      history.push('/rules/rate-source-weight')
    } else {
      history.push(`/${key}`)
    }
  }

  useEffect(() => {
    if (screen.lg) setExpanded(true)
    else setExpanded(false)
  }, [screen])

  const exp = window.localStorage.getItem('expiration')
  const sleeping = window.localStorage.getItem('sleeping')

  useEffect(() => {

    (async function val() {
      const Process = async () => {
        if (exp === null) return
        if (hasTheTokenExpired(parseInt(exp, 10)) && (sleeping === "false" || sleeping === null)) {
          window.localStorage.setItem('sleeping', true) 
          dispatch(requestPostRefreshToken()) 
        }
      }

      await Process();
    })() 

  }, [dispatch, exp, sleeping])

   

  useEffect(() => {
    if (TOKEN !== null) return
    dispatch(logout())
    notify('info', 'Your session has expired')
    removeStorage('altptk')
    removeStorage('altprtk')
    removeStorage('altpntk')
    removeStorage('expiration') 
    socket.disconnect()
    history.push('/login')

  }, [dispatch, history, TOKEN])

  // useEffect(() => {
  //   const warning = setTimeout(() => {
  //     Swal.fire({
  //       icon: 'warning',
  //       title: 'Your session will expire in 5 minutes',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     })
  //   }, 6480000)

  //   const expired = setTimeout(() => {
  //     Swal.fire({
  //       icon: 'info',
  //       title: 'Your session has expired',
  //       showConfirmButton: false,
  //       timer: 1500,
  //     })
  //     clearTimeout(warning)
  //     dispatch(logout())
  //     removeStorage('altptk')
  //     removeStorage('altprtk')
  //     removeStorage('altpntk')
  //     removeStorage('expiration')
  //     socket.disconnect()
  //     history.push('/login')
  //   }, 6840000)

  //   return () => {
  //     clearTimeout(warning)
  //     clearTimeout(expired)
  //   }
  // }, [dispatch, history])

  return (
    <StyledContainer>
      <MenuContainer trigger={null} collapsible collapsed={!expanded}>
        <SidebarHeader>
          <img alt="Ally Logo" src={expanded ? AllyLarge : AllySmallWhite} />
        </SidebarHeader>
        <StyledMenu mode="inline" triggerSubMenuAction="click" selectedKeys={[menuKey]}>
          <StyledItem
            key="dashboard"
            onClick={handleClickMenu}
            icon={
              <div>
                <MdDashboard fontSize="24px" />
              </div>
            }
          >
            {expanded && <div className="item-content">My Dashboard</div>}
          </StyledItem>

          {role !== 'Basic' && (<StyledItem
            key="lane"
            onClick={handleClickMenu}
            icon={
              <div>
                <ListAlt width="24px" />
              </div>
            }
          >
            {expanded && <div className="item-content">Lane History</div>}
          </StyledItem>)}


          <StyledItem
            key="customer"
            onClick={handleClickMenu}
            icon={
              <div>
                <IoIosList size="24px" />
              </div>
            }
          >
            {expanded && <div className="item-content" style={{ fontSize: "13px" }}>Cust. Dashboard</div>}
          </StyledItem>


          {role === 'Admin' && (
            <StyledItem
              key="customers"
              onClick={handleClickMenu}
              icon={
                <div>
                  <img alt="Customers" src={Customers} />
                </div>
              }
            >
              {expanded && <div className="item-content">Customers</div>}
            </StyledItem>
          )}

          {role !== 'Basic' && (
            <StyledItem
              key="rules"
              onClick={handleClickMenu}
              icon={
                <div>
                  <MdAssignment fontSize="24px" />
                </div>
              }
            >
              {expanded && <div className="item-content">Rules</div>}
            </StyledItem>
          )}

          {role !== 'Basic' && (
            <StyledItem
              key="general-settings"
              onClick={handleClickMenu}
              icon={
                <div>
                  <IoMdSettings fontSize="24px" />
                </div>
              }
            >
              {expanded && <div className="item-content">Admin</div>}
            </StyledItem>
          )}
        </StyledMenu>

        <StyledCollapser
          data-testid="arrow-testid"
          onClick={() => {
            setExpanded(!expanded)
          }}
        >
          {expanded ? (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: 'white' }}
            >
              <MdKeyboardArrowLeft color="#fff" size="1.2rem" style={{ marginRight: '10px' }} /> Hide menu
            </div>
          ) : (
            <MdKeyboardArrowRight color="#fff" size="1.2rem" />
          )}
        </StyledCollapser>
      </MenuContainer>
      <LayoutContainer expanded={expanded ? '200px' : '80px'}>
        <TopBar />
        <ChildrenContainer>{children}</ChildrenContainer>
      </LayoutContainer>
    </StyledContainer>
  )
}

export default Layout
