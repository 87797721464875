import React, { useState } from 'react'
import FloatLabel from 'components/Label/FloatLabel'

const InputWithLabel = ({ label, floatLabel, labelStyle, children }) => {
  const [show, setShow] = useState(false)

  const hasValue = (value) => {
    if(value === 0) setShow(true)
    if(value !== 0) setShow(!!value)
  }

  return (
    <FloatLabel label={label} show={floatLabel !== undefined ? floatLabel : show} style={labelStyle}>
      {children(hasValue)}
    </FloatLabel>
  )
}

export default InputWithLabel
