import React from 'react'
import { useSelector } from 'react-redux'
import { Popover } from 'antd'
import Box from 'components/Box/BoxGeneric'
import Tabs from 'components/Tabs'
import { LinkButton } from 'components/Button/TextButton'
import BoxSnapshot, { BoxSnapshotTitle, BoxSnaphotConent } from 'components/Box/BoxSnapshot'
import { removeRulesNoFlatAndMarginZero } from 'utils/filters'
import returnNA from './Utils/returnNA'
import Details from './Utils/Details'
import DisplayComponent from './Utils/DisplayComponent'
import colors from './Utils/constants'
import CardLTRatio from './Utils/CardLTRatio'


const CarrierCost = () => {
  const { TabPane } = Tabs
  const {
    rateBasic,
    success,
    ruleAdjustments,
    multiSteptAdjustments,
    datRate,
    gsRate,
    gsDatalake,
    mci,
    gsPredictionRate,
    ltrOrigin,
    ltrDestination,
  } = useSelector((state) => ({
    rateBasic: state.getRate.rate,
    datRate: state.getRate.rate?.base_rate?.datRate,
    gsRate: state.getRate.rate?.base_rate?.gsRate,
    gsDatalake: state.getRate.rate?.internal_adjustment,
    success: state.getRate.success,
    ruleAdjustments: state.getRate.rate.ruleAdjustment,
    multiSteptAdjustments: state.getRate.rate.multiStepAdjustment,
    mci: state.getRate.rate?.marketConditions,
    gsPredictionRate: state.getRate.rate?.base_rate?.gsPredictionRate,
    ltrOrigin: state?.getRate?.rate?.ltrOrigin,
    ltrDestination: state?.getRate?.rate?.ltrDestination,
  }))
  const convertToNumber = (string) => `$${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed())}`
  const totalRulesAdjusments =
    removeRulesNoFlatAndMarginZero(ruleAdjustments).length +
    removeRulesNoFlatAndMarginZero(multiSteptAdjustments).length

  const stringIsNegative = (value) => {
    return String(value ?? '').match(/-/) !== null
  }

  const addStyle = (value) => {
    return stringIsNegative(value) ? {} : { color: 'red' }
  }
  const mciComponent = (component) => {
    if (mci) {
      if (mci?.INBOUND && mci?.OUTBOUND) {
        if (mci?.INBOUND?.historyMciScore || mci?.OUTBOUND?.historyMciScore) return component
        if (mci?.INBOUND?.forecastMciScore || mci?.OUTBOUND?.forecastMciScore) return component
      }
    }
    return null
  }

  const greenScreensComponent = (component) => {
    try {
      if (gsRate && gsRate.rate) {
        if (gsRate?.rate === '$0.00') {
          return null
        }
        return component
      }
      return null
    } catch (error) {
      return null
    }
  }

  const gsDatalakeComponent = (component) => {
    try {
      if (gsDatalake && gsDatalake.rate) {
        // Avoid display Lane History Snapshot when number of reports is equal 0
        const numberOfReports = Number(gsDatalake?.extraInfo?.reports || '0')
        if (numberOfReports > 0) return component
      }
      return null
    } catch (error) {
      return null
    }
  }

  const datContractComponent = (component) => {
    try {
      if (!datRate.datContract) {
        return null
      }
      if (datRate.datLow.rate === '$0.00' || datRate.datContract.rate === '$0.00') {
        return null
      }
      return component
    } catch (error) {
      return null
    }
  }

  const datRateComponent = (component) => {
    try {
      if (datRate?.datAvg || datRate?.datLow || datRate?.datHigh) {
        if (datRate?.datAvg?.rate || datRate?.datLow?.rate || datRate?.datHigh?.rate) {
          if (datRate?.datAvg?.rate !== '$0.00' || datRate?.datLow?.rate !== '$0.00' || datRate?.datHigh?.rate !== '$0.00') {
            return component
          }
        }
      }
      return null
    } catch (error) {
      return null
    }
  }

  const validateDATTooltip = () => {
    if (datRate && datRate.datLow) {
      return datRate.datLow.rate === '$0.00'
    }
    return true
  }

  const validateGS = () => {
    if (gsRate && gsRate?.rate && gsRate?.rate) {
      return gsRate?.rate === '$0.00'
    }
    return true
  }
  const inNumber = (string, fixed) => `${Number(Number(string.replace(/[^0-9.-]+/g, '')).toFixed(fixed))}`
  const datRateTotal = validateDATTooltip() ? 0 : inNumber(datRate.total)
  const gsRateTotal = validateGS() ? 0 : inNumber(gsRate?.total ?? '')
  const gsPredictionRateTotal = validateGS() ? 0 : inNumber(gsPredictionRate?.total ?? '')
  const avgLaneHistory = convertToNumber(gsDatalake?.rate ?? '')
  const HyperLink = ({ title = '', url = null }) => {
    if (url === null) {
      return title
    }
    return (
      <LinkButton style={{ textDecoration: 'underline' }} target="_blank" href={url} type="link">
        {title}
      </LinkButton>
    )
  }

  const ltOriginValidation = ltrOrigin && [...ltrOrigin].length > 0
  const ltDestinationValidation = ltrDestination && [...ltrDestination].length > 0

  const displayLtrDestination = <DisplayComponent ValueToEvaluate={() => ltOriginValidation && ltDestinationValidation}
    ComponentToDisplay={<CardLTRatio ltrOrigin={ltrOrigin} ltrDestination={ltrDestination} />} />

  return (
    <Box title="Carrier Cost Estimate" secondary height="auto">
      {success ? (
        <Tabs defaultActiveKey="1" size="small">
          <TabPane tab="Snapshot" key="1">
            {datRateComponent(
              <Popover
                title={
                  <p
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    DAT Review
                  </p>
                }
                content={
                  <div>
                    <p>
                      <strong>Origin:</strong> {datRate?.datExtraInfo?.originGeography || 'N/A'}
                    </p>
                    <br />
                    <p>
                      <strong>Destination:</strong> {datRate?.datExtraInfo?.destinationGeography || 'N/A'}
                    </p>
                    <br />
                    <p>
                      <strong>Miles:</strong> {datRate?.datExtraInfo?.miles || 'N/A'}
                    </p>
                    <br />
                    <p>
                      <strong>Contributors:</strong> {datRate?.datExtraInfo?.contributors || 'N/A'}
                    </p>
                    <br />
                    <p>
                      <strong>Reports:</strong> {datRate?.datExtraInfo?.moves || 'N/A'}
                    </p>
                    <br />
                    <p>
                      <strong>Time Frame:</strong>{' '}
                      {datRate?.datExtraInfo?.daysBack ? `${datRate?.datExtraInfo?.daysBack} days` : 'N/A'}
                    </p>
                  </div>
                }
                placement="right"
              >
                <BoxSnapshot color={colors[9]}>
                  <BoxSnapshotTitle color={colors[8]}>
                    <HyperLink title="DAT Rateview" url={datRate?.url} />
                  </BoxSnapshotTitle>
                  <BoxSnaphotConent>
                    <div className="spanContainer ">
                      <span className="label">Low</span>
                      <span className="tagValue">{convertToNumber(datRate?.datLow?.rate ?? '')} USD</span>
                    </div>
                    <div className="spanContainer">
                      <span className="label">Avg</span>
                      <span className="tagValue">{convertToNumber(datRate?.datAvg?.rate ?? '')} USD</span>
                    </div>
                    <div className="spanContainer ">
                      <span className="label">High</span>
                      <span className="tagValue">{convertToNumber(datRate?.datHigh?.rate ?? '')} USD</span>
                    </div>
                  </BoxSnaphotConent>
                </BoxSnapshot>
              </Popover>
            )}
            {greenScreensComponent(
              <BoxSnapshot color={colors[11]}>
                <BoxSnapshotTitle color={colors[8]}>
                  <HyperLink title="Greenscreens" url={gsRate?.url} />
                </BoxSnapshotTitle>
                <BoxSnaphotConent style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="spanContainer">
                    <span className="label">Network</span>
                    <span className="tagValue">{convertToNumber(gsRate?.rate ?? '')} USD</span>
                  </div>
                  <div className="spanContainer ">
                    <span className="label">Target</span>
                    <span className="tagValue">{convertToNumber(gsPredictionRate?.rate ?? '')} USD</span>
                  </div>
                </BoxSnaphotConent>
              </BoxSnapshot>
            )}
            {gsDatalakeComponent(
              <Popover
                title={
                  <p
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    Ally Lane History
                  </p>
                }
                content={
                  <div>
                    <p>
                      <strong># Reports (outlier):</strong> {gsDatalake?.extraInfo?.reports_outlier || '0'}
                    </p>
                    <p>
                      <strong>Timeframe:</strong> {gsDatalake?.extraInfo?.timeframe || 'N/A'}
                    </p>
                  </div>
                }
                placement="right"
              >
                <BoxSnapshot color={colors[12]}>
                  <BoxSnapshotTitle color={colors[8]}>Ally Lane History</BoxSnapshotTitle>
                  <BoxSnaphotConent style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="spanContainer">
                      <span className="label">Avg</span>
                      <span className="tagValue" data-testid="ALH-avg-value">
                        {returnNA(avgLaneHistory) ? 'N/A' : `${avgLaneHistory} USD`}
                      </span>
                    </div>
                    <div className="spanContainer ">
                      <span className="label"># of Reports</span>
                      <span className="tagValue" data-testid="ALH-reports-value">
                        {' '}
                        {gsDatalake?.extraInfo?.reports || '0'}
                      </span>
                    </div>
                  </BoxSnaphotConent>
                </BoxSnapshot>
              </Popover>
            )}
            {displayLtrDestination}
            {mciComponent(
              <BoxSnapshot color={colors[14]}>
                <BoxSnapshotTitle color={colors[8]}>Market Conditions</BoxSnapshotTitle>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span className="tagValue">Origin</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className="spanContainer">
                        <span className="label">Last 7 Days</span>
                        <span className="tagValue" style={addStyle(mci?.INBOUND?.historyMciScore ?? '')}>
                          {mci?.INBOUND?.historyMciScore ?? ''}
                        </span>
                      </div>
                      <div className="spanContainer ">
                        <span className="label">Forecast</span>
                        <span className="tagValue" style={addStyle(mci?.INBOUND?.forecastMciScore ?? '')}>
                          {mci?.INBOUND?.forecastMciScore ?? ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <span className="tagValue">Destination</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className="spanContainer">
                        <span className="label">Last 7 Days </span>
                        <span className="tagValue" style={addStyle(mci?.OUTBOUND?.historyMciScore ?? '')}>
                          {mci?.OUTBOUND?.historyMciScore ?? ''}
                        </span>
                      </div>
                      <div className="spanContainer ">
                        <span className="label">Forecast</span>
                        <span className="tagValue" style={addStyle(mci?.OUTBOUND?.forecastMciScore ?? '')}>
                          {mci?.OUTBOUND?.forecastMciScore ?? ''}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </BoxSnapshot>
            )}
            {datContractComponent(
              <BoxSnapshot color={colors[10]}>
                <BoxSnapshotTitle color={colors[8]}>Contract</BoxSnapshotTitle>
                <div className="spanContainer">
                  <span className="tagValue">{convertToNumber(datRate?.datContract?.rate ?? '')} USD</span>
                </div>
              </BoxSnapshot>
            )}
          </TabPane>
          <TabPane tab="Details" key="2">
            <Details
              datRate={datRate}
              gsRate={gsRate}
              gsPredictionRate={gsPredictionRate}
              gsDatalake={gsDatalake}
              rateBasic={rateBasic}
              datRateTotal={datRateTotal}
              gsRateTotal={gsRateTotal}
              gsPredictionRateTotal={gsPredictionRateTotal}
              ruleAdjustments={ruleAdjustments}
              multiSteptAdjustments={multiSteptAdjustments}
              totalRulesAdjusments={totalRulesAdjusments}
            />
          </TabPane>
        </Tabs>
      ) : (
        <div>
          <p>
            Empty section, please, enter the data to make the quote and you will be able to view the Dashboard
            information.
          </p>
        </div>
      )}
    </Box>
  )
}
export default CarrierCost
