import React, { useState, useEffect } from 'react'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import SelectInput from 'components/Form/SelectInput'
import Select from 'antd/lib/select'
import colors from 'providers/theme/config/colorPallete'
import { BsPlusSquareFill } from 'react-icons/bs'
import ClosablePill from 'containers/rules/Ruleset/CreateRuleset/utils/ClosablePill'
import TextInput from 'components/Form/TextInput'

const formName = 'select-tms-system'
const defaultValues = {
  tms: '',
  code: '',
}

const tmsSystems = {
  Emerge: {
    tms: 'Emerge',
    code: '',
  },
  E2Open: {
    tms: 'E2Open',
    code: '',
  },
  Ally: {
    tms: 'Ally Endpoint',
    code: '',
  },
}

export default function TMSsystems({ mappings = [], handleMappings = (some) => some }) {
  const [form] = Form.useForm()
  const [tms, setTms] = useState(null)
  const [selectedTmsSystems, setSelecteTmsSystems] = useState([])
  const [load, setLoad] = useState(false)

  useEffect(() => {
    if (!load && mappings.length > 0) {
      setSelecteTmsSystems(mappings)
      setLoad(true)
    }
  }, [mappings, load])

  useEffect(() => {
    handleMappings(selectedTmsSystems)
  }, [selectedTmsSystems, handleMappings])

  const handleValuesChange = (values) => {
    if (values.tms) setTms(tmsSystems[values.tms])
  }

  const addTmsSystem = () => {
    form
      .validateFields()
      .then(() => {
        if (tms) {
          const code = form.getFieldValue('code')
          const tmsSet = new Set([...selectedTmsSystems, { ...tms, code }])
          const newSelectedTms = [...tmsSet]
          setSelecteTmsSystems(newSelectedTms)
          setTms(null)
          form.resetFields()
        }
      })
      .catch((e) => {
        console.error({ e })
      })
  }

  const items = selectedTmsSystems.map((item, i) => {
    return (
      <ClosablePill
        key={item.tms}
        text={`${item.tms} - ${item.code}`}
        onClose={() => {
          const newSelected = [...selectedTmsSystems]
          newSelected.splice(i, 1)
          setSelecteTmsSystems(newSelected)
        }}
      />
    )
  })

  return (
    <Row>
      <Col span={21}>
        <Form
          form={form}
          layout="vertical"
          name={formName}
          initialValues={defaultValues}
          onValuesChange={handleValuesChange}
          style={{ marginTop: 6 }}
          hideRequiredMark
        >
          <Row gutter={[26, 10]}>
            <Col span={12}>
              <SelectInput
                label="TMS systems"
                name="tms"
                rules={[{ required: true, message: 'Select a TMS system' }]}
              >
                <Select.Option value="Emerge">Emerge</Select.Option>
                <Select.Option value="E2Open">E2Open</Select.Option>
                <Select.Option value="Ally">Ally Endpoint</Select.Option>
              </SelectInput>
            </Col>
            <Col span={12}>
              <TextInput data-testid="code-tms-test" label="Code" name="code" rules={[{ required: true, message: 'Please type a code' }]} />
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={3}>
        <BsPlusSquareFill
          style={{
            fontSize: '36px',
            color: colors.light_blue.default,
            marginTop: 6,
            marginLeft: 14,
            cursor: 'pointer',
          }}
          onClick={() => addTmsSystem()}
        />
      </Col>
      <Col span={24}>
        {selectedTmsSystems.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 16,
              flexWrap: 'wrap',
            }}
          >
            {items}
          </div>
        )}
      </Col>
    </Row>
  )
}
