export default function (api) {
  return {
    createRate: (body) => api.post('api/quotes', body),
    getAllRates: (params) =>
      api.get('api/quotes', {
        params: {
          ...params,
          ...(params.limit ? { ...params.limit } : { limit: 100 }),
        },
      }),
    getRate: async (params) =>
      api.get('api/quotes/get/rate', {
        params: {
          ...params,
        },
      }),
    deleteRate: (query) => api.delete(`api/quotes/${query}`),
    downloadMultipleRate: (params) => api.get('api/quotes/download/history', { params: { quotes: params } }),
    sendEmail: (email, params) => {
      api.post(`/api/quotes/sendEmail/${email}`, { ...params })
    },
    loadWon: (id, load) => {
      api.patch(`/api/quotes/${id}?loadWon=${load}`)
    },
    getGreenScreenRate: (params) => api.get('api/quotes/get/historicalgs', { params: { ...params } }),
  }
}
