import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestGetAutocompleteHere } from 'store/duck/getAutocompleteHere.duck'
import { requestGetZipHere, clearGetZipHere } from 'store/duck/getZipHere.duck'
import { requestGetZipAutoComplete } from 'store/duck/getZipAutoComplete.duck'
import { saveLane } from 'store/duck/saveQuote.duck'
import { requestGetCityFromZip } from 'store/duck/getCityFromZip.duck'

const useAutocomplete = () => {
  const {
    dataZip,
    data,
    success,
    editRate,
    zipCodes,
    dataFromZipCode,
    fetchingData,
    // dataZipFetching,
  } = useSelector((state) => ({
    success: state.getZipHere.success,
    dataZip: state.getZipHere.data,
    // dataZipFetching: state.getZipHere.fetching,

    data: state.getAutocompleteHere.data,
    fetchingData: state.getAutocompleteHere.fetching,

    editRate: state.createRate.editRate,
    zipCodes: state.getZipAutoComplete.zipCodes,
    dataFromZipCode: state.getCityFromZip.data,
  }))

  const [value, setValue] = useState(' ')
  const [dropdownData, setDropdownData] = useState(data || [])
  const [isNumberInput, setIsNumberInput] = useState(false)
  const [fetching, setfetching] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setfetching(fetchingData)
  }, [fetchingData])

  useEffect(() => {
    if (isNumberInput && value.length === 5) {
      dispatch(requestGetCityFromZip(value))
    } else if (value) {
      dispatch(requestGetAutocompleteHere(value))
    }
  }, [dispatch, value, isNumberInput])

  useEffect(() => {
    if (success && Object.keys(dataZip).length > 0 && !dataZip.wayPoint && !editRate) {
      dispatch(saveLane(dataZip))
      dispatch(clearGetZipHere())
    }
  }, [dataZip, dispatch, success, editRate])

  const handleAutocompleteChange = (searchText) => {
    const regex = /\d+/g
    const hasNumbers = regex.test(searchText)
    setIsNumberInput(hasNumbers)
    setValue(searchText)
  }
  const handleSelect = (selectedValue, target) => {
    if (selectedValue === 'Los Angeles, CA, United States') {
      dispatch(requestGetZipHere(selectedValue, target))
    } else dispatch(requestGetZipHere(selectedValue.replace(', United States', ''), target))
  }

  useEffect(() => {
    if (data.length > 0) dispatch(requestGetZipAutoComplete(data))
  }, [data, dispatch])

  useEffect(() => {
    const editedArray = data.map((element, index) => {
      let newObj = {}
      if (zipCodes && zipCodes.length > 0 && zipCodes[index]) {
        newObj = { ...data[index] }
        newObj.value = `${element.value} - ${zipCodes[index]}`
      }
      return newObj
    })
    const finalDropdownData = isNumberInput ? dataFromZipCode : editedArray
    setDropdownData(finalDropdownData)
  }, [data, dataFromZipCode, zipCodes, isNumberInput])

  return [dropdownData, handleAutocompleteChange, handleSelect, fetching]
}

export default useAutocomplete
